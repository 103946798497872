import { useState, useEffect } from 'react';
import { Collapse, UnmountClosed } from 'react-collapse';
import clsx from 'clsx';
import tracker from '@/lib/tracker';
import Icon from '@/assets/icons';

import styles from './Accordion.module.scss';

const Accordion = ({
	title,
	children = [],
	headChild,
	disabled = false,
	mod = '',
	initialOpen = false,
	trackerName,
	trackerSection,
	unmountOnClose,
	isFilter,
	clickOpen,
	isFooter,
}) => {
	const [open, setOpen] = useState(initialOpen);
	const CollapseComponent = unmountOnClose ? UnmountClosed : Collapse;

	useEffect(() => {
		setOpen(initialOpen);
	}, [initialOpen]);

	const toggle = () => {
		if (!disabled) {
			setOpen(!open);

			if (clickOpen && !open) {
				clickOpen();
			}
		}

		if (trackerName && trackerSection) {
			try {
				tracker[trackerSection][trackerName]();
			} catch (e) {
				console.log(e, 'err tracker in accordion');
			}
		}
	};

	return (
		<div
			className={clsx(
				{
					[styles.firstBorder]: mod === 'firstBorder',
					[styles.product]: mod === 'product',
					[styles.productShop]: mod === 'product-shop',
					[styles.mobileFilterCategory]: mod === 'mobileFilterCategory',
					[styles.cart]: mod === 'cart',
					[styles.footer]: isFooter,
				},
				styles.accordion,
				'accordion',
			)}
			// mod={mod}
		>
			<div
				className={clsx(
					{ [styles.open]: open, [styles.disabled]: disabled },
					styles.toggle,
					isFilter && styles.toggleFilter,
					isFooter && styles.toggleFooter,
				)}
				role='button'
				onClick={toggle}
				disabled={disabled}
			>
				<div
					className={clsx(
						styles.toggleContent,
						isFilter && styles.toggleContentFilter,
						isFooter && styles.toggleContentFooter,
					)}
				>
					{title}
					{headChild}
					<div className={styles.toggleIcon}>
						<Icon
							verticalClassName={styles.iconHorizontal}
							horizontalClassName={styles.iconVertical}
							name='plus'
						/>
					</div>
				</div>
			</div>

			<CollapseComponent isOpened={open}>
				<div
					className={clsx(
						styles.content,
						isFilter && styles.contentFilter,
						isFooter && styles.contentFooter,
					)}
					style={{ minHeight: `${children.length * 57 > 228 ? 228 : children.length * 57}px` }}
				>
					{children}
				</div>
			</CollapseComponent>
		</div>
	);
};

export default Accordion;
