const trackYMGoal = goal => params => {
	try {
		window.ym('56654761', 'reachGoal', goal, params);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error('YM trackGoal exception', e);
	}
};

export default {
	pages: {
		blockViewed: trackYMGoal('block-viewed'),
		linkButtonClicked: trackYMGoal('click-button-pages'),
	},
	productPage: {
		addToCart: trackYMGoal('add-to-cart-product'),
		goToCart: trackYMGoal('click-cart-product'),
		addToFavorites: trackYMGoal('click-to-favorites-product'),
		availabilityInStores: trackYMGoal('availability'),
		wishlistSimilarProducts: trackYMGoal('wishlist-similar-products'),
		clickSimilarProducts: trackYMGoal('click-similar-products'),
		watchingVideo: trackYMGoal('watching-video'),
	},
	header: {
		goToCart: trackYMGoal('click-cart'),
		goToEmtyCart: trackYMGoal('view-cart-empty'),
		goToWishlist: trackYMGoal('click-wishlist-header'),
		goToAuth: trackYMGoal('click-login-header'),
	},
	catalog: {
		addToFavorites: trackYMGoal('click-to-favorites-catalog'),
		onlySale: trackYMGoal('only-sale'),
		clearFilters: trackYMGoal('clear-filters'),
	},
	order: {
		checkout: trackYMGoal('click-order'),
	},
	cart: {
		successPromocode: trackYMGoal('discount-card-success'),
		unsuccessPromocode: trackYMGoal('discount-card-unsuccess'),
		successOrder: trackYMGoal('order-success-js'),
	},
	lk: {
		saveDiscountCard: trackYMGoal('discount-card-login'),
	},
	checkout: {
		backToCart: trackYMGoal('back-to-cart'),
		errorFormValidateCreateOrder: trackYMGoal('click-order-unsuccess'),
	},
	likeness: {
		addToCart: trackYMGoal('to-cart-fashion'),
		addInWishlist: trackYMGoal('wishlist-fashion'),
		clickMoreInfo: trackYMGoal('more-information-fashion'),
	},
	auth: {
		getSmsCode: trackYMGoal('take-sms-login'),
		resendSmsCode: trackYMGoal('resend-sms'),
		login: trackYMGoal('click-sign-login'),
		loginSuccess: trackYMGoal('login-success'),
	},
};
